'use strict';

//TODO: FYI, changes to this file needs to be updated at https://github.com/bringg/do-kubernetes/blob/master/qaenv/charts/2-services/charts/webapp/templates/_configs.tpl as well!

var REGIONS_ENUM = {
  'development': 0,
  'us-east-1': 2,
  'us-east-1-gcp': 3,
  // 4 was used for au environment
  'eu-west-1-gcp': 5,
  'us-east-4-gcp': 6,
  'eu-west-3-gcp': 7,
  'us-central-1-gcp': 8
};

// define global var
var ENV_CONFIG = {
	"admin_api_endpoint": "https://admin-api.bringg.com",
	"default_region": "us-east-4-gcp",
	"environment": "production",
	"airbrake_project_id": 206532,
	"bi_dashboard_url": "https://dashboard.bringg.com/",
	"billing_app_url": "https://app.bringg.com/apps/billing/index.html",
	"analytics_reports_app_url": "https://app.bringg.com/apps/analytics/reports/index.html",
	"airbrake_project_key": "4e7fc527a1320a7fc8a05362a3e8f1bf",
	"mapbox_access_token": "pk.eyJ1IjoiYnJpbmdnIiwiYSI6ImNrOXNsMDRuZjE1NmMzbG54ZW9xeWZ0OGYifQ.JIUbF4r-kPUSrZZBgGRYXQ",
	"log_rocket_app_id": "2l1kpg/bringg-web",
	"general_env_api_url": "https://ge-eu1-api.bringg.com",
	"user_analytics": {
		"enabled": true,
		"api_key": "GTM-W63RQR6"
	},
	"regions_common_config": {
		"stripe_key": "pk_live_JlpkK4S1ILT2c0nojHZomU0Y",
		"stripe_client_id": "ca_6nwY6gWDDAOAkguNxRh3YEtOzh1FxdrC",
		"socket_websocket_port": 443,
		"recaptcha_site_key": "6Lc6zW8UAAAAAP7qUUzTHSxfQw5Qro_CQrNYCljj"
	}
}
;

// FALLBACK_REGIONS_CONFIG is assigned in Gruntfile.js ( Look for: fallback_regions_config )
var FALLBACK_REGIONS_CONFIG;
FALLBACK_REGIONS_CONFIG = {
  "default_region": "us-central-1-gcp",
  "regions": {
    "eu-west-1-gcp": {
      "id": 5,
      "connectivity_check_url": "https://eu2-api.bringg.com/health/connectivity",
      "identity_api_url": "https://eu2-admin-api.bringg.com",
      "mobile_api_url": "https://eu2-api.bringg.com",
      "real_time_url": "https://eu2-realtime.bringg.com",
      "web_api_url": "https://eu2-admin-api.bringg.com"
    },
    "eu-west-3-gcp": {
      "id": 7,
      "connectivity_check_url": "https://eu3-api.bringg.com/health/connectivity",
      "identity_api_url": "https://eu3-admin-api.bringg.com",
      "mobile_api_url": "https://eu3-api.bringg.com",
      "real_time_url": "https://eu3-realtime.bringg.com",
      "web_api_url": "https://eu3-admin-api.bringg.com"
    },
    "us-east-4-gcp": {
      "id": 6,
      "connectivity_check_url": "https://us2-api.bringg.com/health/connectivity",
      "identity_api_url": "https://us2-admin-api.bringg.com",
      "mobile_api_url": "https://us2-api.bringg.com",
      "real_time_url": "https://us2-realtime.bringg.com",
      "web_api_url": "https://us2-admin-api.bringg.com"
    },
    "us-central-1-gcp": {
      "id": 8,
      "connectivity_check_url": "https://us3-api.bringg.com/health/connectivity",
      "identity_api_url": "https://us3-admin-api.bringg.com",
      "mobile_api_url": "https://us3-api.bringg.com",
      "real_time_url": "https://us3-realtime.bringg.com",
      "web_api_url": "https://us3-admin-api.bringg.com"
    }
  }
}
;

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.href);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function enrichFallbackRegionsConfig(config, commonRegionConfigKeys) {
  if (!FALLBACK_REGIONS_CONFIG) return config;

  var newRegions = {};
  for (var regionName in FALLBACK_REGIONS_CONFIG.regions) {
    newRegions[regionName] = Object.assign({}, FALLBACK_REGIONS_CONFIG.regions[regionName], commonRegionConfigKeys);
  }
  config.default_region = FALLBACK_REGIONS_CONFIG.default_region;
  config.regions = newRegions;
  return config;
}

var commonRegionConfigKeys = ENV_CONFIG.regions_common_config;
var mergedConfigs = enrichFallbackRegionsConfig(new Object(ENV_CONFIG), commonRegionConfigKeys);
var region = localStorage.getItem("bringg-region") || getParameterByName('region') || mergedConfigs.default_region;
console.log('config loading with region: '  + region);
var config = mergedConfigs.regions[region] ? mergedConfigs.regions[region] : mergedConfigs.regions[mergedConfigs.default_region];

function forEachConfig(fn) {
  Object.keys(config).forEach(function(key) {
    var configKey = key.toUpperCase(),
      configValue = config[key];

    fn(configKey, configValue);
  });
}

if (typeof app !== "undefined") {
  app.constant("ENV_CONFIGURATION", ENV_CONFIG);
  window['COMMON_REGION_CONFIG_KEYS'] = commonRegionConfigKeys;
  forEachConfig(function(key, value) {
    app.constant(key, value);
  });

  app.config(function(bringgConfigurationProvider) {
    bringgConfigurationProvider.initialize(ENV_CONFIG);
  });

  // "ENV" is not used actually
  app.constant('ENV', region)
     .constant('ITEMS_PER_PAGE', 50)
     .constant('SUBSCRIPTION_TYPE', {'PRO': 8, 'BASIC': 3, 'O2M': 19});
} else {
  forEachConfig(function(key, value) {
    window[key] = value;
  });
}